import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import Contact from "./Contact.js";
import {BiPhoneCall} from "react-icons/bi";
import {TfiEmail} from "react-icons/tfi";
import {FaWhatsapp} from "react-icons/fa";

const Footer = () => {
    let date = new Date()
    let location = useLocation();
    let locationPath = location.pathname;

    return (
        <footer id="footer">
            {/*{locationPath === "/" ? (<div className="container"><Contact/></div>) : ""}*/}
            {/*<div className="row d-flex align-content-center justify-content-center border-bottom py-2">*/}
            {/*<div className="col-md-3 border-end p-2 d-none d-md-block">*/}
            {/*    <span>Conctatez-nous au </span>*/}
            {/*</div>*/}
            {/*<div className="col-4 border-end">*/}
            {/*    <a href="tel:13 54" className="flex align-content-center justify-content-center text-decoration-none text-light p-2">*/}
            {/*        <BiPhoneCall size={20} className="me-2"/>*/}
            {/*        <span className="d-none d-md-block">1354</span>*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*<div className="col-4 border-end">*/}
            {/*    <a href="mailto:ecoute.ci@nsiaassurances.com" className="flex align-content-center justify-content-center text-decoration-none text-light p-2">*/}
            {/*        <TfiEmail size={20} className="me-2"/>*/}
            {/*        <span className="d-none d-md-block">ecoute.ci@nsiaassurances.com</span>*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*<div className="col-4">*/}
            {/*    <a href="https://wa.me/+2250575102102" className="flex align-content-center justify-content-center text-decoration-none text-light p-2">*/}
            {/*        <FaWhatsapp size={20} className="me-2"/>*/}
            {/*        <span className="d-none d-md-block">+225 05 75 102 102</span>*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*</div>*/}

            <div className="copyright d-flex align-items-center justify-content-between">
                <span className="me-3 fw-bold">Vous pensez qu'un de vos proches disparus a un contrat à terme chez nous ? <a
                    href="https://recherchedecontrat.nsiavieassurances.ci" target="_blank" className="blink"
                    style={{color: "#ffc10794", fontWeight: 'bold'}}>Cliquez ici</a></span>
                <span className="d-flex">
                    <a href="tel:13 54"
                       className="flex align-content-center justify-content-center text-decoration-none text-light p-2">
                        <BiPhoneCall size={20}/> <small className="d-none d-md-block ps-1"> 1354</small>
                    </a> <span className="mt-2">|</span>
                    <a href="mailto:ecoute.ci@nsiaassurances.com"
                       className="flex align-content-center justify-content-center text-decoration-none text-light p-2">
                        {/*<TfiEmail size={20}/> <small className="d-none d-md-block ps-1">ecoute.ci@nsiaassurances.com</small>*/}
                        <TfiEmail size={20}/>
                    </a> <span className="mt-2">|</span>
                    <a href="https://wa.me/+2250575102102"
                       className="flex align-content-center justify-content-center text-decoration-none text-light p-2">
                        {/*<FaWhatsapp size={20}/> <small className="d-none d-md-block ps-1"> +225 05 75 102 102</small>*/}
                        <FaWhatsapp size={20}/>
                    </a>
                </span>
            </div>
            <span className="d-flex align-items-center justify-content-center border-top border-secondary pt-1" style={{fontSize: '13px'}}>© {date.getFullYear()} | By <a href="https://www.jarstechnologies.com" target="_blank" className="ms-1" style={{color: '#ffc10794', textDecoration: 'none'}}>JARS Technologies</a></span>
        </footer>
    )
}

export default Footer
