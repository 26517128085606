import React, { useContext } from 'react'
import { AppContext } from '../../../context/ContextApi.js';
import RdvForm from './RdvForm.js';
import RdvRecap from './RdvRecap.js';
import Breadcrumb from "../faq/ContextViewer/Breadcrumb";
import {Link} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {IoIosArrowBack} from "react-icons/io";

const RdvMulti = () =>
{
    const { step, rdvSteps } = useContext(AppContext)
    const renderCurrentSelection = () =>
    {
        switch (step)
        {
            case 1:
                return <RdvForm />
            case 2:
                return <RdvRecap />
            default:
                return null;
        }
    }

    return (
        <>
            <div className="card-title flex justify-between items-center p-3 mb-0">
                <h3>DEMANDE DE RENDEZ-VOUS</h3>
                <Link to="/assistance" type="button" className="btn bts-sm btn-light rounded-pill">
                    <span className="flex justify-center items-center">
                        <IoIosArrowBack size={20} /> <label className="d-none d-md-inline m-1">Retour</label>
                    </span>
                </Link>
            </div>
            <div className="card-content p-4 lightest-main">
                <div className="login-container-mobile">
                    {renderCurrentSelection()}
                </div>
            </div>
        </>

    )
}

export default RdvMulti
