import React from 'react';
import { useField } from 'formik';

const DateOfBirthInput = ({ field, form, ...props }) => {
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        // Remove all non-numeric characters
        const numericValue = inputValue.replace(/\D/g, '');

        let formattedValue = numericValue;
        // Insert slashes at appropriate positions
        if (numericValue.length > 2) {
            formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`;
        }
        if (numericValue.length > 4) {
            formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4)}`;
        }

        form.setFieldValue(field.name, formattedValue);
    };

    return (
        <input
            {...field}
            {...props}
            value={field.value}
            onChange={handleInputChange}
            placeholder="DD/MM/YYYY"
            maxLength="10"
        />
    );
};

export default DateOfBirthInput;
